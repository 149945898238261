<template>
  <div class="recommend">
    <div class="topBox">
      <div class="title">热门UP主</div>
      <div class="changeBox" @click="$router.push('/popularUpMaster')">更多</div>
    </div>
    <div class="upList">
      <div class="upItem" v-for="item in list" :key="item.uid">
        <div class="avatarBox" :class="{ isCertifica: item.merchantUser == 1 }">
          <div @click="jumpHomePage(item)" class="img_box">
            <ImgDecypt :src="item.portrait" round />
          </div>
          <div class="certification" v-if="item.superUser == 1">
            <Vlogo :vid="item.uid + '' + vIndex" />
          </div>
          <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1" />
          <!-- <svg-icon class="certification" iconClass="certification" v-if="item.superUser == 1"></svg-icon> -->
        </div>
        <div class="userNanme" :class="{ vipName: item.isVip }">
          {{ item.name }}
        </div>
        <!-- <div class="summary">{{item.summary}}</div>

                <div :class="item.hasFollow ? 'followed' : 'focusOn'"  @click="careOrcancle(item)">{{item.hasFollow ? '已关注' : '+ 关注'}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';
import { careOrcancle } from '@/api/user';
import { queryUpRecom } from '@/api/community';
export default {
  components: {
    Vlogo,
    ImgDecypt,
  },
  props: ['vIndex'],
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.queryList();
  },
  methods: {
    jumpHomePage(item) {
      this.$router.push({ path: '/userHomePage', query: { uid: item.uid } });
    },
    async queryList() {
      let res = await this.$Api(queryUpRecom);
      if (res && res.code == 200) {
        this.list = res.data;
      }
    },
    // 关注-取消关注
    async careOrcancle(item) {
      let req = {
        followUID: parseInt(item.uid),
        isFollow: !item.hasFollow,
      };
      if (!item.uid) {
        Toast('当前用户信息错误');
        return;
      }
      let res = await this.$Api(careOrcancle, req);
      if (res.code === 200) {
        Toast(req.isFollow ? '关注成功' : '取消成功');
        item.hasFollow = req.isFollow;

        return;
      }
      Toast(res.tip || `${req.isFollow ? '关注失败' : '取消失败'}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  box-sizing: border-box;

  .topBox {
    padding: 12px 16px 0;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .title {
      font-size: 17px;
    }

    .changeBox {
      font-size: 14px;
      color: #999999;

      .changeBtn {
        margin-right: 6px;
      }
    }
  }

  .upList {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: auto;

    .upItem {
      display: inline-block;
      width: 85px;
      padding: 0 2px;
      // height: 191px;
      // border: 1.5px solid #ffffff10;
      // margin-right: 12px;

      .avatarBox {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        margin: 16px auto 10px;
        position: relative;

        .img_box {
          width: 56px;
          height: 56px;
        }

        .certification {
          width: 18.1px;
          height: 18.2px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }

        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }

      .userNanme {
        height: 22px;
        width: 85px;
        font-size: 14px;
        color: #333333;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .vipName {
        color: #ff678f;
      }
      .summary {
        font-size: 14px;
        height: 22px;
        margin-top: 4px;
        text-align: center;
        padding: 0 13px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #ffffff50;
      }

      .focusOn {
        font-size: 14px;
        width: 56px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        margin: 11px auto 0;
        border: 1px solid #ed851d;
        color: #ed851d;
        text-align: center;
      }
      .followed {
        font-size: 14px;
        width: 56px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        margin: 11px auto 0;
        color: #fff;
        background: #363636;
        text-align: center;
      }
    }

    .upItem:last-child {
      margin-right: 0;
    }
  }
}
</style>
