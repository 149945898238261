<template>
  <div class="recommend">
    <van-tabs
      class="recommendTabs oepnX"
      v-model="activeName"
      @click="selectTag"
      animated
      :scrollspy="false"
      :swipeable="true"
      background="#ffffff"
      title-active-color="#ff678f"
      title-inactive-color="#7a7a7a"
      v-if="commnityTag && commnityTag.length"
      ref="tabsRef"
    >
      <van-tab v-for="tag in commnityTag" :title="tag.name" :key="tag.id" :name="tag.id"></van-tab>
    </van-tabs>
    <!--帖子-->
    <PullRefresh
      v-if="!isLoading"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      :is-higeht-max="true"
      :adv-list="advList"
      :hasAdvPagination="true"
      className="ComRecommend"
    >
      <WorkRecom :hotWorks="hotWorks" v-if="hotWorks && hotWorks.length && activeName == '最新'" :activeName="activeName" />
      <div v-for="(postInfo, index) in list" :key="postInfo.type == 'banner' ? postInfo.id + index : getKey(postInfo)">
        <Post
          class="post"
          :index="index"
          :page="pageNumber"
          :activeName="activeName"
          :postInfo="postInfo"
          @followedAllSuccess="followedAllSuccess"
          v-if="postInfo.type != 'banner' && postInfo.type != 'upRecom'"
        />
        <!-- <div class="bannerBox" v-if="postInfo.type == 'banner'" @click="goAdv(postInfo)">
          <ImgDecypt :src="postInfo.cover" class="banner" />
        </div> -->
        <UpRecom :vIndex="index" v-if="postInfo.type == 'upRecom'" class="upRecom" />
        <div v-if="(index + 1) % 4 == 0 && adList.length > 0" class="adImg">
          <ImgDecypt :itemData="getAdUrl(adList)" />
        </div>
      </div>
    </PullRefresh>
    <Loading v-if="isLoading" />
    <div class="marquee_box" v-if="marqueeText && marqueeShow">
      <van-notice-bar :text="marqueeText" background="#ff678f" color="#ffffff" speed="40" mode="closeable">
        <template slot="left-icon">
          <div class="left_icon">
            <svg-icon iconClass="notice_text"></svg-icon>
            ：
          </div>
        </template>
        <template slot="right-icon">
          <div class="right_icon" @click="marqueeShow = false">
            <svg-icon iconClass="close10"></svg-icon>
          </div>
        </template>
      </van-notice-bar>
    </div>
  </div>
</template>
<script>
import { AdType, getAdItem, getMarquee, MarqueeType } from '@/utils/getConfig';
import { NoticeBar, Tab, Tabs } from 'vant';
import Post from '@/components/Post';
import UpRecom from '@/components/UpRecom';
import WorkRecom from '@/components/WorkRecom';
import { queryCommunityList, queryHotList } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import { getBeijinTime } from '@/utils';
import { getSessionItem, removeSession } from '@/utils/longStorage';
import { mapState } from 'vuex';
import { jumpAdv } from '@/utils/getConfig';
import ImgDecypt from '@/components/ImgDecypt';
import Loading from '@/components/Loading';

export default {
  name: 'ComRecommend',
  props: ['isOpenDarkWeb'],
  components: {
    [NoticeBar.name]: NoticeBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    UpRecom,
    WorkRecom,
    Loading,
    Post,
    PullRefresh,
    ImgDecypt,
  },
  activated() {
    // this.$store.dispatch('user/getVipTiroCountdown');
    if (getSessionItem('followInfo')) {
      let followInfo = JSON.parse(getSessionItem('followInfo'));
      this.list.forEach((i) => {
        if (!i.publisher) return;
        if (followInfo.uid == i.publisher.uid) {
          i.publisher.hasFollowed = followInfo.status;
        }
      });
    }
  },
  deactivated() {
    getSessionItem('followInfo') || removeSession('followInfo');
  },
  data() {
    return {
      isLoading: true,
      advList: [],
      advArr: [],
      pageNumber: 1,
      pageSize: 10,
      ReqTime: '',
      type: 0,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      activeName: '',
      adList: [],
      cacheIndex: 'sns_insert_index',
      hotWorks: [],
      marqueeText: '',
      marqueeShow: true,
    };
  },
  computed: {
    ...mapState({
      commnityTag: (state) => state.commnity.commnityTag,
    }),
    isSuccess() {
      return this.$store.state.user.updateCommunityR.isSuccess;
    },
  },
  watch: {
    isSuccess: function (val) {
      if (!val) return;
      this.$store.commit('user/UPDATE_COMMUNITY_R', {
        isSuccess: false,
        id: '',
      });
      let index = this.$store.state.user.commentData.index;
      let id = this.$store.state.user.updateCommunityR.id;
      //先通过评论索引去修改
      if (index >= 0) {
        this.list[index].commentCount++;
        return;
      }
      //如果评论索引没找到，通过id去修改
      if (id) {
        let fIndex = this.list.findIndex((el) => el.id === id);
        this.list[fIndex].commentCount++;
      }
    },
    '$store.state.commnity.refreshSta': async function (status) {
      if (status) {
        this.list = [];
        await this.onRefresh();
        this.$store.commit('commnity/CHANGE_REFRESHSTA', false);
      }
    },
  },
  async created() {
    await this.getAd();
    this.marqueeText = getMarquee(MarqueeType.community);
    this.advList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    this.advArr = getAdItem(AdType.COMMUNITY_POST);

    if (this.commnityTag?.length == 0) {
      await this.$store.dispatch('commnity/setCommintyTag');
    }
    if (this.commnityTag?.length) {
      this.activeName = this.commnityTag[0].id;
      if (this.activeName == '最新') {
        this.queryHotWorkList();
      }
      this.getList();
    }
  },
  methods: {
    async queryHotWorkList() {
      let req = {
        pageNumber: 1,
        pageSize: 9,
        reqDate: getBeijinTime(),
      };
      let res = await this.$Api(queryHotList, req);
      if (res && res.code == 200) {
        this.hotWorks = res.data.list;
      }
    },
    getKey(info) {
      if (info.forWardUser) {
        return info.id + '' + info.forWardUser;
      }
      return info.id;
    },
    tabBack() {
      let index = this.commnityTag.findIndex((e) => e.name == '暗网');
      let id = this.commnityTag[index - 1].id;
      this.activeName = id;
      this.refreshInfo();
    },
    selectTag(name, title) {
      if (title?.indexOf('暗网') != -1) {
        this.$emit('open');
        return;
      }
      this.$emit('lastTitle', name);
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getList('refresh');
      if (this.activeName == '最新') {
        this.queryHotWorkList();
      }
    },
    refreshInfo() {
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getList('refresh');
    },
    useActiveName(title) {
      this.activeName = title;
    },
    async getList(type) {
      try {
        let req = {
          pageNumber: String(this.pageNumber),
          pageSize: String(this.pageSize),
          tag: this.activeName,
          reqDate: getBeijinTime(),
        };
        let res = await this.$Api(queryCommunityList, req);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (list.length >= 10 && this.advArr && this.advArr.length > 0) {
            let advs = this.advArr.splice(0, 1);
            advs[0].type = 'banner';
            this.advArr.push(...advs);
            list.push(...advs);
          }

          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.pageNumber === 1 && this.list.length === 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.list.length < req.pageSize) {
            this.finished = true;
          }
          if (this.pageNumber == 1 && this.list.length >= 5) {
            this.list.splice(5, 0, { type: 'upRecom' });
          }
          if (this.pageNumber == 3 && this.list.length >= 25) {
            this.list.splice(25, 0, { type: 'upRecom' });
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    followedAllSuccess(uid) {
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].publisher) continue;
        if (this.list[i].publisher?.uid === uid) {
          this.list[i].publisher.hasFollowed = true;
        }
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.getList('refresh');
    },
    /**
     * * 跳转广告
     *  * @param {*} item 广告信息
     */
    goAdv(item) {
      jumpAdv(item);
    },
    async getAd() {
      if (this.$store.getters.adsCommunityList.list.length > 0) {
        this.adList = this.$store.getters.adsCommunityList.list;
      }
    },
    getAdUrl(adList) {
      let adIndex = sessionStorage.getItem(this.cacheIndex);
      if (!adIndex) {
        adIndex = 0;
      } else {
        adIndex = Number(adIndex);
      }
      let itemAd = adList[adIndex];
      if (adIndex + 1 > adList.length - 1) {
        adIndex = 0;
      } else {
        adIndex = adIndex + 1;
      }
      sessionStorage.setItem(this.cacheIndex, adIndex);
      return itemAd;
    },
  },
};
</script>
<style lang="scss" scoped>
.marquee_box {
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 18px;
  z-index: 101;
  .left_icon {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 21px;
    }
  }
  .right_icon {
    display: flex;
    align-items: center;
    padding-left: 8px;
    svg {
      font-size: 24px;
    }
  }
  /deep/ .van-notice-bar {
    border-radius: 18px;
    height: 36px;
  }
}
.recommend {
  height: $communityHeight;
  padding-top: 10px;
  box-sizing: border-box;

  /deep/ .adv {
    margin-bottom: 0;
  }

  .bannerBox {
    margin-top: 16px;
    padding: 0 16px;
    background: #1e1e1e00;
    height: 224px;
    /deep/ img {
      border-radius: 10px;
    }
    /deep/ .vanImage {
      background: #00000000 !important;
    }
  }

  .recommendTabs {
    /deep/ .van-tab__text {
      font-size: 16px;
    }
  }
  /deep/ .van-tabs__wrap {
    border-bottom: 0.7px solid #e0e0e0;
  }

  /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-right: unset !important;
  }

  /deep/ .van-tab {
    padding: 0;
    font-size: 16px;
    margin-right: 19px;
  }
  /deep/ .van-tab:first-child {
    margin-left: 10px;
  }

  /deep/ .van-tab:last-child {
    margin-right: 10px;
  }

  /deep/ .van-tabs__nav {
    justify-content: normal;
  }

  /deep/ .van-tab {
    flex: none;
  }
  /deep/ .van-tabs__line {
    background: #ff678f;
  }

  .left_icon {
    svg {
      font-size: 18px;
      padding-right: 8px;
    }
    span {
      font-size: 12px;
    }
  }

  .post {
    padding: 11px 16px 17px 16px;
  }
  .van-list div:not(:nth-child(1)) .post {
    margin-top: 16px;
  }
  .van-list .recommend + div > .post {
    margin-top: 0;
  }
}
.adImg {
  width: 100%;
  // height: calc(540px / 343 * 86 - 0.374rem - 0.374rem);
  height: 86px;
  // padding: 0.35rem 0.374rem 0 0.374rem;
  padding: 13px 14px 0 14px;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  /deep/ img,
  .vanImage {
    border-radius: 6px;
  }
  /deep/ .img_Decypt {
    border-radius: 6px;
    overflow: hidden;
  }
}
</style>
