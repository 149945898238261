<template>
  <div class="recommend">
    <div class="topBox">
      <div class="title">热门作品</div>
      <div class="changeBox" @click="$router.push({ path: '/popularWork', query: { activeName } })">更多</div>
    </div>
    <div class="upList">
      <div class="upItem" v-for="item in hotWorks" :key="item.id" @click="goPlay(item)">
        <div class="avatarBox">
          <div class="img_box">
            <ImgDecypt :src="item.cover" />
            <div class="video_icon">
              <div class="v_gold" v-if="item.originCoins > 0 && !item.watch?.isFreeWatch">
                <svg-icon icon-class="gold" />
                <i class="tip_vip"></i>
                <span>{{ item.originCoins }}</span>
              </div>
              <div class="v_vip" v-if="item.originCoins == 0 && !item.watch?.isFreeWatch">
                <i class="tip_vip"></i>
              </div>
            </div>
            <div class="video_info">
              <div class="watch">
                <svg-icon icon-class="tip_play" />
                <span>{{ item.playCount | watchCount }}</span>
              </div>
              <div class="time">
                <span>{{ item.playTime | videotime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="userNanme">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
export default {
  components: {
    ImgDecypt,
  },
  props: ['hotWorks', 'activeName'],
  methods: {
    goPlay(video) {
      if (video.newsType == 'SP') {
        this.$router.push({
          path: '/communityVideo',
          query: {
            vid: video.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  box-sizing: border-box;
  color: #333333;

  .topBox {
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .title {
      font-size: 16px;
    }

    .changeBox {
      font-size: 14px;
      color: #999999;
    }
  }

  .upList {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: auto;

    .upItem {
      display: inline-block;
      margin-right: 10px;

      .avatarBox {
        width: 111px;
        height: 177px;
        margin: 10px auto 6px;
        position: relative;

        .img_box {
          width: 111px;
          height: 177px;
          border-radius: 5px;
          overflow: hidden;
          .video_icon {
            position: absolute;
            top: 0;
            left: -0.5px;
            font-size: 12px;

            .v_gold {
              height: 21px;
              padding: 0 8px;
              line-height: 21px;
              // text-align: center;
              border-radius: 6px 0 11.5px 0;
              color: white;
              background: linear-gradient(#ff4737, #ff455b);

              svg {
                padding-right: 4px;
              }
            }

            .v_vip {
              .tip_vip {
                display: inline-block;
                width: 41px;
                height: 21px;
                background: url('../../assets/png/tip_vip.png') no-repeat;
                background-size: 100%;
              }
            }
          }
          .video_info {
            width: 100%;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            height: 18px;
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 11px;
            padding: 10px 4px;
            color: #dcdee0;

            .watch {
              > svg {
                padding-right: 6px;
              }
            }
          }
        }
      }

      .userNanme {
        width: 111px;
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333333;
      }
    }

    .upItem:last-child {
      margin-right: 0;
    }
  }
}
</style>
